import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import TopAppBar from "../Main/TopAppBar";
import ClubImage from "./ClubImage";
import ActivityList from "../Activity/ActivityList";
import useCrewData from "../Common/CrewData";
import { Button, Paper } from "@mui/material";
import NoticeList from "../Notice/NoticeList";
import ClubPublicInfo from "./ClubPublicInfo";
import axios from "axios";
import ErrorHandler from "../Common/ErrorHandler";
import conf from "../../config/conf_sql.json";

function ClubMain() {
  const { club_nick } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);
  const [club, setClub] = useState({});
  const linkto =
    club_nick.toLocaleUpperCase() === "YRC"
      ? `https://open.kakao.com/o/gvOlNToc`
      : club_nick.toLocaleUpperCase() === "JTR"
      ? `https://open.kakao.com/o/g29Q1e1f`
      : ``;
  const welcomePath =
    conf.media + "/uploads/" + club_nick.toLowerCase() + "_welcome.png";

  useEffect(() => {
    axios
      .get(`/api/v1/clubs/list`)
      .then((response) => {
        const club = response.data.find(
          (club) => club.nick.toUpperCase() === club_nick.toUpperCase()
        );
        if (club) {
          setClub(club);
        }
      })
      .catch((e) => {
        ErrorHandler(e, "ClubMain.js, get club data");
      });
  }, [club_nick]);

  if (adminLevel > 0) {
    return (
      <div className="Main">
        <TopAppBar club_nick={club_nick} />
        <Link to={linkto} target="blank">
          <Paper
            key="image"
            elevation={0}
            sx={{ margin: 2, backgroundColor: "transparent" }}
          >
            <img
              key={"welcome"}
              className="img"
              alt={club_nick}
              src={welcomePath}
            />
          </Paper>
        </Link>
        <NoticeList
          order="desc"
          text="Latest Notices"
          api={`/api/v1/notices/club_nick/${club_nick}/list/category/공지`}
          club_nick={club_nick}
          crew_id={crewId}
          club_id={clubId}
          crew_nick={crewId}
          admin_level={adminLevel}
          limit={3}
        />
        <br />
        <ActivityList
          text="Following Activities"
          order="asc"
          api={`/api/v1/activities/club/${clubId}/following/day`}
          club_nick={club_nick}
          adminLevel={adminLevel}
        />
        <ClubImage club_nick={club_nick} />
      </div>
    );
  } else if (club.nick) {
    return (
      <>
        <TopAppBar club_nick={club_nick} />
        <ClubImage club_nick={club_nick} />
        {memberId !== "" && (
          <Link
            to={`/${club_nick}/signup`}
            state={{ timeAuthorized: Date.now() }}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                fontSize: "20px",
                display: "block",
              }}
            >{`${club_nick.toUpperCase()} 가입`}</Button>
          </Link>
        )}
        <ClubPublicInfo />
      </>
    );
  } else {
    return (
      <>
        <TopAppBar />
      </>
    );
  }
}

export default ClubMain;
