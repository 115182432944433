import React from "react";
import { Link } from "react-router-dom";
import TopAppBar from "./TopAppBar";
import Welcome from "./Welcome";

export default function CrewWITH() {
  return (
    <div>
      <TopAppBar />
      <Welcome />

      <div style={{ padding: "20px" }}>
        {" "}
        <h2>CrewWITH</h2>
        <h3>
          <Link
            to={"/termsofservice"}
            style={{ color: "#3f50b5", textDecoration: "inherit" }}
          >
            서비스 이용약관
          </Link>{" "}
          히스토리
        </h3>
        <p>2024년 6월 25일: 서비스 이용약관 최초 시행</p>
        <h3>
          <Link
            to={"/privacypolicy"}
            style={{ color: "#3f50b5", textDecoration: "inherit" }}
          >
            개인정보 처리방침
          </Link>{" "}
          히스토리
        </h3>
        <p>2024년 6월 25일: 개인정보 처리방침 최초 시행</p>
      </div>
    </div>
  );
}
