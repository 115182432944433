import React, { useState, useEffect } from "react";
import ApiHeader from "./ApiHeader";

const LikeButton = ({ crew_id, table_type, table_id, size }) => {
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  // 초기 좋아요 상태와 카운트 가져오기
  useEffect(() => {
    if (!crew_id || !table_type || !table_id) return;

    ApiHeader.get(`/api/v1/likes/${table_type}/${table_id}/count`)
      .then((response) => {
        setLikeCount(response.data.count);
      })
      .catch((e) => {
        console.error("Failed to fetch like count:", e);
      });

    ApiHeader.get(`/api/v1/likes/${crew_id}/${table_type}/${table_id}`)
      .then((response) => {
        setLiked(response.data.count > 0); // 좋아요 여부 판단
      })
      .catch((e) => {
        console.error("Failed to fetch like count:", e);
      });
  }, [crew_id, table_type, table_id]);

  // 좋아요 클릭 핸들러
  const handleLike = () => {
    if (liked) {
      // 좋아요 취소 (DELETE 요청)
      ApiHeader.delete(`/api/v1/likes/${crew_id}/${table_type}/${table_id}`)
        .then(() => {
          setLikeCount((prev) => prev - 1);
          setLiked(false);
        })
        .catch((e) => {
          console.error("Failed to unlike:", e);
        });
    } else {
      // 좋아요 추가 (POST 요청)
      ApiHeader.post("/api/v1/likes", {
        crew_id,
        table_type,
        table_id,
      })
        .then(() => {
          setLikeCount((prev) => prev + 1);
          setLiked(true);
        })
        .catch((e) => {
          console.error("Failed to like:", e);
        });
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <button
        onClick={handleLike}
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          fontSize: size,
          transition: "transform 0.3s ease",
        }}
      >
        {liked ? "❤️" : "🤍"}
      </button>
      <span style={{ fontSize: size * 0.8, alignContent: true }}>
        {likeCount}
      </span>
    </div>
  );
};

export default LikeButton;
