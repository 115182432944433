import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FormControl, FormLabel, TextField, Button } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import moment from "moment";
import conf from "../../config/conf_sql.json";
import useCrewData from "../Common/CrewData";
import TopAppBar from "../Main/TopAppBar";
import ApiHeader from "../Common/ApiHeader";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";

function ActivityCreate() {
  const { club_nick, activity_id } = useParams();
  const { memberId, clubId, crewId, adminLevel } = useCrewData(club_nick);

  const location = useLocation();
  const cName =
    location.state != null && location.state.name != null
      ? location.state.name
      : "";
  const cMaximum =
    location.state != null && location.state.max_crew != null
      ? location.state.max_crew
      : 40;
  const cLevel =
    location.state != null && location.state.attend_level != null
      ? location.state.attend_level
      : 1;
  const cDate =
    location.state != null && location.state.activity_time != null
      ? moment(location.state.activity_time)
          .subtract(conf.time_offset)
          .format("YYYY-MM-DD")
      : "";
  const cTime =
    location.state != null && location.state.activity_time != null
      ? moment(location.state.activity_time)
          .subtract(conf.time_offset)
          .format("HH:mm")
      : "";
  const cLocation =
    location.state != null && location.state.location != null
      ? location.state.location
      : "";
  const cUrl =
    location.state != null && location.state.location_url != null
      ? location.state.location_url
      : "";
  const cSubgroup =
    location.state != null && location.state.subgroup != null
      ? location.state.subgroup
      : "";
  const cInfo =
    location.state != null && location.state.info != null
      ? location.state.info
      : "";
  const type =
    location.state != null && location.state.type != null
      ? location.state.type
      : "";

  const [vName, setName] = useState(cName);
  const [vMaximum, setMaximum] = useState(cMaximum);
  const [vLevel, setLevel] = useState(cLevel);
  const [vDate, setDate] = useState(cDate);
  const [vTime, setTime] = useState(cTime);
  const [vLocation, setLocation] = useState(cLocation);
  const [vUrl, setUrl] = useState(cUrl);
  const [vSubgroup, setSubgroup] = useState(cSubgroup);
  const [vInfo, setInfo] = useState(cInfo);
  const [isAuthorized, setIsAuthorized] = useState(false);

  // location.state에서 timeAuthorized 값 가져오기
  const { timeAuthorized } = location.state || { timeAuthorized: 0 };
  useEffect(() => {
    const currentTime = Date.now();
    if (currentTime - timeAuthorized <= 10000) {
      // 10초 이내인지 확인
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [timeAuthorized]);

  const [clubLevels, setClubLevels] = useState({
    1: "lv1",
    2: "lv2",
    3: "lv3",
    4: "lv4",
    5: "lv5",
    6: "lv6",
    7: "lv7",
    8: "lv8",
    9: "lv9",
  });

  useEffect(() => {
    if (clubId === "") return;
    ApiHeader.get(`/api/v1/clubs/${clubId}`).then((response) => {
      setClubLevels(response.data.levels && JSON.parse(response.data.levels));
    });
    if (adminLevel === 5) {
      setLevel(5);
    }
  }, [clubId]);

  useEffect(() => {
    if (!activity_id) {
      const storedDate = sessionStorage.getItem("selectedDate");
      const storedTimestamp = sessionStorage.getItem("selectedDateTimestamp");
      const currentTime = new Date().getTime();
      const yesterday = moment().subtract(1, "days").startOf("day");

      if (
        storedDate &&
        storedTimestamp &&
        currentTime - storedTimestamp < 60000
      ) {
        const date = moment(new Date(storedDate));
        if (date.isAfter(yesterday)) {
          setDate(date.format("YYYY-MM-DD"));
        }
      }
    }
  }, [activity_id]);

  const saveActivity = async () => {
    const dateTime = moment(vDate + " " + vTime + ":00").format(
      "YYYY-MM-DD HH:mm:ss"
    );
    if (type == "put") {
      ApiHeader.put(`/api/v1/activities/${activity_id}`, {
        activity_time: dateTime,
        name: vName,
        max_crew: vMaximum,
        attend_level: vLevel,
        location: vLocation,
        location_url: vUrl,
        subgroup: vSubgroup,
        info: vInfo,
      });
      navigate(`/${club_nick}/activity/${activity_id}`);
    } else {
      // activity 테이블에서 제일 큰 id값을 가져옴
      const responseMaxId = await ApiHeader.get("/api/v1/activities/max_id");
      const newActId = responseMaxId.data[0].max_id + 1;
      ApiHeader.post("/api/v1/activities", {
        id: newActId,
        club_id: hashids.decode(clubId)[0],
        crew_id: hashids.decode(crewId)[0],
        activity_time: dateTime,
        name: vName,
        max_crew: vMaximum,
        location: vLocation,
        location_url: vUrl,
        subgroup: vSubgroup,
        info: vInfo,
        attend_level: vLevel,
        state: adminLevel >= 7 ? 2 : 1,
      });

      // 새로 추가된 activity_id를 가지고, attendees 테이블에 crewId와 subgroup은 "리더"로 추가합니다.
      ApiHeader.post("/api/v1/attendees", {
        crew_id: hashids.decode(crewId)[0],
        activity_id: newActId,
        subgroup: "리더",
        state: 1,
      });
      navigate(`/${club_nick}/activity/${hashids.encode(newActId)}`);
    }
  };

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [isOK, setIsOK] = useState(false);
  const navigate = useNavigate();
  const eventClick = () => {
    if (
      vName != "" &&
      vMaximum != "" &&
      vLevel != "" &&
      vDate != "" &&
      vTime != "" &&
      moment(vDate + " " + vTime).isAfter(moment()) &&
      vLocation != ""
    ) {
      setMessage(
        activity_id ? "모임 수정 하시겠습니까?" : "모임 등록 하시겠습니까?"
      );
      setIsOK(true);
    } else if (moment(vDate + " " + vTime).isBefore(moment())) {
      setMessage("과거 시간은 등록할 수 없습니다.");
      setIsOK(false);
    } else {
      setMessage("모두 작성해 주시기 바랍니다.");
      setIsOK(false);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = () => {
    if (isOK) {
      try {
        saveActivity();
      } catch (e) {
        ErrorHandler(e, "ActivityCreate.js, handleAgree");
      }
    }
    setOpen(false);
  };

  return isAuthorized ? (
    <>
      <TopAppBar club_nick={club_nick} />
      <Box
        sx={{
          maxWidth: "800px",
          margin: 1,
        }}
      >
        <FormControl fullWidth>
          <FormLabel id="create" sx={{ fontSize: 32 }}>
            {activity_id ? "모임 수정" : "모임 등록"}
          </FormLabel>
          <br />
          <FormLabel id="name" sx={{ fontSize: 16, mb: 0.5 }}>
            모임 이름
          </FormLabel>
          <TextField
            required
            id="name"
            label="Name"
            value={vName}
            onChange={(e) => setName(e.target.value)}
            size="small"
          />
          <br />
          <FormLabel id="attendee" sx={{ fontSize: 16, mb: 0.5 }}>
            모임 정원
          </FormLabel>
          <TextField
            required
            id="attendee"
            label="Maximum Attendee"
            value={vMaximum}
            onChange={(e) => setMaximum(e.target.value)}
            size="small"
          />
          <br />
          <FormLabel id="attend_level" sx={{ fontSize: 16, mb: 0.5 }}>
            모임 참석 등급
          </FormLabel>
          <Select
            value={vLevel}
            onChange={(e) => setLevel(e.target.value)}
            required
            size="small"
            id="attend_level"
            disabled={adminLevel <= 5}
          >
            {clubLevels &&
              Object.entries(clubLevels).map(
                ([key, value]) =>
                  value !== "" && (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  )
              )}
          </Select>
          <br />
          <FormLabel id="date" sx={{ fontSize: 16, mb: 0.5 }}>
            모임 시간
          </FormLabel>
          <TextField
            required
            id="date"
            label="Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={vDate}
            onChange={(e) => setDate(e.target.value)}
            size="small"
          />
          <br />
          <TextField
            required
            id="time"
            label="Time"
            type="time"
            InputLabelProps={{ shrink: true }}
            value={vTime}
            onChange={(e) => setTime(e.target.value)}
            size="small"
          />
          <br />
          <FormLabel id="location" sx={{ fontSize: 16, mb: 0.5 }}>
            모임 장소
          </FormLabel>
          <TextField
            required
            id="location"
            label="Location"
            value={vLocation}
            onChange={(e) => setLocation(e.target.value)}
            size="small"
          />
          <br />
          <TextField
            id="url"
            label="Location URL"
            value={vUrl}
            onChange={(e) => setUrl(e.target.value)}
            size="small"
          />
          <br />
          <FormLabel id="subgroup" sx={{ fontSize: 16, mb: 0.5 }}>
            모임 소그룹
          </FormLabel>
          <TextField
            multiline
            id="subgroup"
            label="subgroup"
            value={vSubgroup}
            onChange={(e) => setSubgroup(e.target.value)}
            size="small"
          />
          <br />
          <FormLabel id="info" sx={{ fontSize: 16, mb: 0.5 }}>
            모임 정보
          </FormLabel>
          <TextField
            multiline
            id="info"
            label="Information"
            value={vInfo}
            onChange={(e) => setInfo(e.target.value)}
            size="small"
          />
          <br />
          <React.Fragment>
            <Button variant="contained" onClick={eventClick}>
              {activity_id ? "수정" : "등록"}
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle id="alert-dialog-title">
                {activity_id ? "모임 수정 확인" : "모임 등록 확인"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>취소</Button>
                <Button onClick={handleAgree} autoFocus>
                  확인
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </FormControl>
      </Box>
    </>
  ) : (
    <div>
      <TopAppBar club_nick={club_nick} />
      잘못된 접근입니다.
    </div>
  );
}

export default ActivityCreate;
