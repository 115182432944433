import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Button, Stack, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

function TopBottom(props) {
  const { club_nick } = useParams();
  const location = useLocation();
  const { adminLevel } = props;

  const handleRefresh = () => {
    window.location.reload();
  };

  // 활성화된 URL에 따른 스타일 반환 (옅은 파란색 배경)
  const getButtonStyle = (path) => {
    return location.pathname === path
      ? { backgroundColor: "#F0F7FF" } // 활성화된 버튼에 옅은 파란색 배경
      : {};
  };

  if (adminLevel > 0) {
    return (
      <Stack spacing={2} direction="row">
        <Link
          to={`/${club_nick}/notices`}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <Button
            variant="text"
            sx={getButtonStyle(`/${club_nick}/notices`)} // 공지 URL 스타일 적용
          >
            공지
          </Button>
        </Link>
        <Link
          to={`/${club_nick}/activities`}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <Button
            variant="text"
            sx={getButtonStyle(`/${club_nick}/activities`)} // 모임 URL 스타일 적용
          >
            모임
          </Button>
        </Link>
        <Link
          to={`/${club_nick}/notices/bulletin`}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <Button
            variant="text"
            sx={getButtonStyle(`/${club_nick}/notices/bulletin`)} // 게시판 URL 스타일 적용
          >
            게시판
          </Button>
        </Link>
        {adminLevel >= 4 && (
          <Link
            to={`/${club_nick}/crews`}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <Button
              variant="text"
              sx={getButtonStyle(`/${club_nick}/crews`)} // 크루 URL 스타일 적용
            >
              크루
            </Button>
          </Link>
        )}
        <IconButton onClick={handleRefresh} color="primary" size="small">
          <RefreshIcon />
        </IconButton>
      </Stack>
    );
  }

  return null; // Return null if adminLevel is 0 or less
}

export default TopBottom;
