import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import InputMask from "react-input-mask";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Checkbox,
  Box,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import axios from "axios";
import TopAppBar from "../Main/TopAppBar";
import ApiHeader from "../Common/ApiHeader";
import conf from "../../config/conf_sql.json";
import Hashids from "hashids";
const hashids = new Hashids(conf.secret, 10);
import ErrorHandler from "../Common/ErrorHandler";

function SignUp() {
  const { member_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  let token = localStorage.getItem("TOKEN_CREWWITH");
  if (!token) {
    navigate("/login");
    return null;
  }
  token = token.replace(/"/g, "");

  const [userData, setUserData] = useState({});
  const [vEmail, setEmail] = useState("");
  const [vName, setName] = useState("");
  const [vGender, setGender] = useState("");
  const [vBirthday, setBirthday] = useState("");
  const [vNationality, setNationality] = useState("대한민국");
  const [vAddress, setAddress] = useState("");
  const [vContact, setContact] = useState("010-");
  const [vCheck, setCheck] = useState(false);
  const [memberId, setMemberId] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(false);

  // location.state에서 timeAuthorized 값 가져오기
  const { timeAuthorized } = location.state || { timeAuthorized: 0 };
  useEffect(() => {
    const currentTime = Date.now();
    if (currentTime - timeAuthorized <= 10000) {
      // 10초 이내인지 확인
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [timeAuthorized]);

  useEffect(() => {
    if (!token) return;
    const fetchMemberId = async () => {
      try {
        const response = await ApiHeader.get(
          `/api/v1/accesslogs/${token}/members`
        );
        if (response.data.length > 0) {
          setMemberId(hashids.encode(response.data[0].id));
        }
      } catch (e) {
        ErrorHandler(e, "SignUp.js, fetchMemberId");
      }
    };

    const fetchMemberData = async () => {
      try {
        const response = await ApiHeader.get(
          `/api/v1/members/${member_id}/decrypt/${token}`
        );
        setName(response.data.name);
        setGender(response.data.gender);
        setBirthday(moment(response.data.birthday).format("YYYY-MM-DD"));
        setNationality(response.data.nationality);
        setAddress(response.data.address);
        if (response.data.contact) {
          setContact(response.data.contact);
        }
        if (response.data.email) {
          setEmail(response.data.email);
        }
      } catch (e) {
        ErrorHandler(e, "SignUp.js, fetchMemberData");
      }
    };

    const fetchKakaoUserData = async () => {
      try {
        const response = await axios.get(`https://kapi.kakao.com/v2/user/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
        setEmail(response.data.kakao_account.email);
      } catch (e) {
        ErrorHandler(e, "SignUp.js, fetchKakaoUserData");
      }
    };

    fetchMemberId();
    if (member_id && member_id === memberId) {
      fetchMemberData();
    }
    fetchKakaoUserData();
  }, [member_id, memberId, token]);

  const saveMemberInfo = async () => {
    try {
      let existId = null;
      if (member_id) {
        existId = hashids.decode(member_id)[0];
      } else {
        const existingMember = await ApiHeader.get(
          `/api/v1/members?name=${vName}&birthday=${vBirthday}`
        );
        if (existingMember.data.length > 0) existId = existingMember.data[0].id;
      }
      if (existId) {
        if (userData.id) {
          await ApiHeader.put(`/api/v1/members/${hashids.encode(existId)}`, {
            kakao_id: userData.id,
            name: vName.trim(),
            gender: vGender,
            birthday: vBirthday,
            nationality: vNationality,
            address: vAddress,
            email: vEmail,
            contact: vContact,
            image_url: userData.kakao_account.profile.profile_image_url,
            info: "",
          });
        } else {
          await ApiHeader.put(
            `/api/v1/members/${hashids.encode(existId)}/without`,
            {
              name: vName.trim(),
              gender: vGender,
              birthday: vBirthday,
              nationality: vNationality,
              address: vAddress,
              email: vEmail,
              contact: vContact,
              info: "",
            }
          );
        }
      } else {
        await ApiHeader.post("/api/v1/members", {
          kakao_id: userData.id,
          name: vName.trim(),
          gender: vGender,
          birthday: vBirthday,
          nationality: vNationality,
          address: vAddress,
          email: vEmail,
          contact: vContact,
          image_url: userData.kakao_account.profile.profile_image_url,
          info: "",
        });
      }

      const prevPath = sessionStorage.getItem("prevPath");
      const parts = prevPath.split("/").filter(Boolean);
      const clubNick = parts[0];
      if (clubNick && !memberId) {
        navigate(`/${clubNick}/signup`, {
          state: { timeAuthorized: Date.now() },
        });
      } else {
        navigate(prevPath);
      }
    } catch (e) {
      ErrorHandler(e, "SignUp.js, saveMemberInfo");
    }
  };

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isOK, setIsOK] = useState(false);

  const eventClick = (event) => {
    const MINIMUM_AGE = 14;
    if (
      vEmail !== "" &&
      vName !== "" &&
      vGender !== "" &&
      vBirthday !== "" &&
      moment().diff(moment(vBirthday), "years") >= MINIMUM_AGE &&
      vNationality !== "" &&
      vAddress !== "" &&
      vContact !== "010-" &&
      vCheck
    ) {
      setMessage(
        member_id
          ? "회원 정보 수정 하시겠습니까?"
          : "환영합니다. 회원 가입 하시겠습니까?"
      );
      setIsOK(true);
    } else if (moment().diff(moment(vBirthday), "years") < MINIMUM_AGE) {
      setMessage(`만 ${MINIMUM_AGE}세 이상만 가입 가능합니다.`);
      setIsOK(false);
    } else {
      setMessage("모두 작성해 주시기 바랍니다.");
      setIsOK(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    if (isOK) {
      saveMemberInfo();
    }
    setOpen(false);
  };

  console.log(isAuthorized, member_id, memberId);

  return isAuthorized && (!member_id || member_id === memberId.toString()) ? (
    <>
      <TopAppBar />
      <Box
        sx={{
          maxWidth: "800px",
          margin: 1,
        }}
      >
        <FormControl fullWidth>
          <FormLabel id="signup" sx={{ fontSize: 32 }}>
            {member_id ? "회원 정보 수정" : "회원 가입"}
          </FormLabel>
          <br />
          <FormLabel id="email" sx={{ fontSize: 16, mb: 0.5 }}>
            이메일
          </FormLabel>
          <TextField
            required
            id="email"
            label="e-mail"
            value={vEmail}
            onChange={(e) => setEmail(e.target.value)}
            size="small"
          />
          <br />
          <FormLabel id="name" sx={{ fontSize: 16, mb: 0.5 }}>
            이름
          </FormLabel>
          <TextField
            required
            id="name"
            label="Name"
            value={vName}
            onChange={(e) => setName(e.target.value)}
            size="small"
          />
          <br />
          <FormLabel id="gender" sx={{ fontSize: 16 }}>
            성별
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="gender"
            name="gender"
            value={vGender}
            onChange={(e) => setGender(e.target.value)}
          >
            <FormControlLabel
              value="Female"
              control={<Radio />}
              label="Female 여"
              size="small"
            />
            <FormControlLabel
              value="Male"
              control={<Radio />}
              label="Male 남"
              size="small"
            />
          </RadioGroup>
          <br />
          <FormLabel id="birthday" sx={{ fontSize: 16, mb: 0.5 }}>
            생년월일
          </FormLabel>
          <TextField
            required
            id="birthday"
            label="Birthday"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={vBirthday}
            onChange={(e) => setBirthday(e.target.value)}
            size="small"
          />
          <br />
          <FormLabel id="nationality" sx={{ fontSize: 16, mb: 0.5 }}>
            국적
          </FormLabel>
          <TextField
            required
            value={vNationality}
            onChange={(e) => setNationality(e.target.value)}
            label="Nationality"
            variant="outlined"
            size="small"
            fullWidth
          />
          <br />
          <FormLabel id="address" sx={{ fontSize: 16, mb: 0.5 }}>
            사는곳 (동까지만 입력)
          </FormLabel>
          <TextField
            required
            id="address"
            label="Address <ex) 서초구 양재동>"
            value={vAddress}
            onChange={(e) => setAddress(e.target.value)}
            size="small"
          />
          <br />
          <FormLabel id="phone" sx={{ fontSize: 16, mb: 0.5 }}>
            연락처
          </FormLabel>
          <InputMask
            mask="010-9999-9999"
            value={vContact}
            onChange={(e) => setContact(e.target.value)}
          >
            {(inputProps) => (
              <TextField
                required
                id="phone"
                label="Contact <ex) 010-1234-5678>"
                value={vContact}
                size="small"
                {...inputProps}
              />
            )}
          </InputMask>
          <p style={{ color: "gray", fontSize: "small" }}>
            [개인정보 수집 및 이용 동의서]
            <br />
            아래와 같이 귀하의 개인정보 수집 및 이용 내용을 개인정보보호법 제
            15조(개인정보의 수집 및 이용) 및 통계법 33조(비밀의 보호 등)에
            의거하여 안내 드리니 확인하여 주시기 바랍니다. <br />
            ① 개인정보의 수집 및 이용 목적: 가입한 회원 확인을 위한 정보 수집,
            서비스 활용, 신규 서비스 개발 및 마케팅/광고 활용 목적
            <br />② 수집하려는 개인정보의 필수 항목: 성명, 성별, 국적, 사진,
            활동 간 촬영되는 사진 및 영상은 공개가 되며 생년월일, 주소, 연락처,
            이메일은 운영진 및 관리자에게만 공개됩니다. 연락처, 이메일은
            암호화하여 저장됩니다.
            <br />③ 미동의 시 불이익: 필수 항목에 해당하는 개인정보 수집 및
            이용에 대한 동의를 거부할 권리가 있으며, 미동의 시 회원 가입이
            불가합니다.
          </p>
          <FormControlLabel
            control={
              <Checkbox
                checked={vCheck}
                onChange={(e) => setCheck(e.target.checked)}
              />
            }
            label="개인정보 수집 및 이용에 동의합니다."
          />
          <br />
          <React.Fragment>
            <Button variant="contained" onClick={eventClick}>
              {member_id ? "수정" : "등록"}
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle id="alert-dialog-title">
                {member_id ? "회원 정보 수정" : "회원 가입 확인"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {isOK && <Button onClick={handleClose}>취소</Button>}
                <Button onClick={handleAgree} autoFocus>
                  확인
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </FormControl>
      </Box>
    </>
  ) : (
    <div>
      <TopAppBar />
      잘못된 접근입니다.
    </div>
  );
}

export default SignUp;
