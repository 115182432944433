import React from "react";
import TopAppBar from "../Main/TopAppBar";

const PrivacyPolicy = () => {
  return (
    <div>
      <TopAppBar />
      <div style={{ padding: "20px", fontSize: "14px", lineHeight: "1.6" }}>
        <h1 style={{ fontSize: "1.8em" }}>CrewWITH 개인정보처리방침</h1>

        <p>
          크루위드(이하 "회사")는 개인정보보호 규정을 준수하며, 관련 법령에
          의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고
          있습니다. 본 개인정보처리방침은 귀하의 정보 수집, 사용 및 정보 공개에
          대한 회사의 정책과 절차를 설명합니다. 귀하는 본 서비스를 사용함으로써
          정보의 수집, 전송, 조작, 저장, 공개 및 본 개인정보처리방침에 명시된
          귀하의 정보를 제공하는 것에 대해 동의하게 됩니다. 귀하의 거주 국가나
          정보의 출처에 관계없이, 귀하의 정보는 서비스가 운영되는 다른 모든
          국가에서 사용될 수 있습니다.
        </p>

        <h2 style={{ fontSize: "1.6em" }}>
          1. 수집하는 개인정보의 항목 및 수집방법
        </h2>
        <h3 style={{ fontSize: "1.4em" }}>가. 수집하는 개인정보의 항목</h3>
        <p>회사는 서비스 제공을 위해 다음과 같은 개인정보를 수집합니다:</p>
        <ul>
          <li>
            필수 정보: 전화번호, 이메일, 이름, 생년월일, 성별, 국적, 주소, 고유
            식별번호(카카오 user_id, 디바이스 아이디 또는 IMEI)
          </li>
          <li>선택 정보: 사진, 가입한 모임, 웹주소, SNS ID, GPS 위치 정보</li>
          <li>
            자동 수집 정보: 트래픽 데이터, 방문 일시, 운영체제 정보, 서비스 이용
            기록, 불량 이용 기록
          </li>
        </ul>

        <h3 style={{ fontSize: "1.4em" }}>나. 개인정보 수집방법</h3>
        <p>회사는 아래 방법으로 개인정보를 수집합니다:</p>
        <ul>
          <li>서비스 가입 및 이용 시 자발적으로 제공받은 정보</li>
          <li>
            서비스 사용 시 자동으로 수집되는 정보(GPS 위치 정보는 사용자가
            동의한 경우에만 수집)
          </li>
        </ul>

        <h2 style={{ fontSize: "1.6em" }}>2. 개인정보의 수집 및 이용 목적</h2>
        <p>회사는 수집한 개인정보를 아래와 같은 목적으로 사용합니다:</p>
        <ul>
          <li>사용자 인증 및 서비스 제공을 위한 필수 정보 관리</li>
          <li>
            이용자의 공개 정보를 캐싱하여 빠른 서비스 제공 및 원활한 기능 수행
          </li>
          <li>
            사용자가 선택한 경우, GPS 정보를 기반으로 위치 트레킹 및 공유 기능
            제공
          </li>
          <li>사용자 위치를 기반으로 주변 클럽 추천 서비스 제공</li>
          <li>커플 매칭 구독 서비스 제공</li>
          <li>유료 클래스 및 관련 서비스 제공</li>
        </ul>

        <h2 style={{ fontSize: "1.6em" }}>3. 개인정보의 제공 및 공유</h2>
        <p>
          회사는 이용자의 동의 없이 제3자에게 개인정보를 제공하지 않습니다.
          다만, 아래의 경우에는 예외로 합니다:
        </p>
        <ul>
          <li>법령에 따라 제공이 요구되는 경우</li>
          <li>
            서비스 제공을 위해 불가피하게 제3자와의 협력이 필요한 경우 (이용자의
            사전 동의가 필요)
          </li>
        </ul>

        <h2 style={{ fontSize: "1.6em" }}>4. 개인정보 처리 위탁</h2>
        <p>
          회사는 원활한 서비스 제공을 위해 외부 업체에 개인정보 처리를 위탁할 수
          있으며, 위탁 시 관련 법령에 따라 안전하게 처리될 수 있도록 계약을
          체결하고 관리합니다.
        </p>

        <h2 style={{ fontSize: "1.6em" }}>5. 개인정보의 보유 및 이용 기간</h2>
        <p>
          회사는 개인정보 수집 및 이용 목적이 달성된 후, 이용자의 개인정보를
          지체 없이 파기합니다. 다만, 관련 법령에 따라 일정 기간 동안 개인정보를
          보유할 수 있습니다:
        </p>
        <ul>
          <li>부정 이용 기록: 1년</li>
          <li>계약 및 청약 철회 관련 기록: 5년</li>
          <li>대금 결제 및 재화 공급에 관한 기록: 5년</li>
          <li>소비자 불만 및 분쟁 처리에 관한 기록: 3년</li>
          <li>본인 확인에 관한 기록: 6개월</li>
          <li>방문 기록: 3개월</li>
        </ul>

        <h2 style={{ fontSize: "1.6em" }}>6. 개인정보 파기 절차 및 방법</h2>
        <p>
          회사는 개인정보 보유 기간이 경과하거나 처리 목적이 달성된 후에는 해당
          정보를 지체 없이 파기합니다.
        </p>
        <ul>
          <li>
            종이에 출력된 개인정보는 분쇄하거나 소각하여 파기하고, 전자적 파일
            형태로 저장된 개인정보는 복구할 수 없는 기술적 방법을 사용해
            삭제합니다.
          </li>
        </ul>

        <h2 style={{ fontSize: "1.6em" }}>7. 이용자의 권리</h2>
        <p>
          이용자는 언제든지 개인정보를 조회하거나 수정할 수 있으며, 서비스 내
          "탈퇴" 기능을 통해 계정 해지를 요청할 수 있습니다. 또한, 법령에 따라
          이용자의 개인정보 열람 및 정정 요구를 할 수 있으며, 그 처리 결과에
          대해 이의를 제기할 수 있습니다.
        </p>

        <h2 style={{ fontSize: "1.6em" }}>
          8. 개인정보 자동 수집 장치의 설치/운영 및 거부
        </h2>
        <p>
          회사는 계정 정보 생성을 위해 카카오 user_id, 디바이스 아이디 또는 IMEI
          등의 식별번호를 자동으로 수집합니다. GPS 정보는 사용자의 동의하에
          수집되며, 사용자가 이를 거부하는 경우에도 서비스의 다른 기능을 이용할
          수 있습니다. 다만, 위치 기반 서비스는 제공되지 않습니다.
        </p>

        <h2 style={{ fontSize: "1.6em" }}>9. 개인정보처리방침의 변경</h2>
        <p>
          회사는 개인정보처리방침을 변경할 수 있으며, 변경 시 최소 7일 전에
          서비스 내 공지 또는 웹사이트를 통해 사전 고지합니다. 긴급한 경우, 지체
          없이 변경 사항을 공지할 수 있습니다.
        </p>

        <h2 style={{ fontSize: "1.6em" }}>10. 개인정보 보호책임자 및 연락처</h2>
        <p>
          개인정보 보호와 관련한 문의 사항은 아래의 개인정보 보호책임자에게
          연락주시기 바랍니다:
        </p>
        <ul>
          <li>개인정보 보호책임자: 정용섭</li>
          <li>이메일: subheim@naver.com</li>
        </ul>

        <h2 style={{ fontSize: "1.6em" }}>11. 시행일</h2>
        <p>본 개인정보처리방침은 2024년 6월 25일부터 시행됩니다.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
