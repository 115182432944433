import React from "react";
import TopAppBar from "../Main/TopAppBar";

const TermsOfService = () => {
  return (
    <div>
      <TopAppBar />
      <div style={{ padding: "20px", fontSize: "14px", lineHeight: "1.6" }}>
        <h1 style={{ fontSize: "1.8em" }}>CrewWITH 이용약관</h1>

        <h2 style={{ fontSize: "1.6em" }}>제 1 조 (목적)</h2>
        <p>
          이 약관은 크루위드(이하 '회사')가 제공하는 CrewWITH 서비스(이하
          '서비스')를 이용하는 서비스 사용자(이하 '회원')와 관련하여 회사와
          회원의 권리, 의무 및 책임사항, 운영수칙, 기타 필요한 사항을 규정함을
          목적으로 합니다.
        </p>

        <h2 style={{ fontSize: "1.6em" }}>제 2 조 (정의)</h2>
        <ol>
          <li>
            "서비스"라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종
            유무선 장치를 포함)와 상관없이 회원이 이용할 수 있는 서비스 및
            회사가 추가 개발하거나 다른 회사와 제휴계약 등을 통해 제공되는
            일체의 서비스를 의미합니다.
          </li>
          <li>
            "회원"이라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와
            이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을
            의미합니다.
          </li>
          <li>
            "아이디"라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고
            회사가 승인하는 문자 또는 문자와 숫자의 조합을 의미합니다.
          </li>
          <li>
            "클럽"이라 함은 회원이 회사의 서비스를 통해 직접 만든 그룹으로, 한
            명 이상의 회원과 관심사를 공유하고 친목을 도모할 수 있도록 회사가
            제공하는 기능을 의미합니다.
          </li>
          <li>
            "클럽장"이라 함은 클럽을 개설하거나 클럽의 대표 권한을 양도받은
            회원을 의미합니다.
          </li>
          <li>
            "운영진"이라 함은 클럽장에 의해 임명된 클럽의 운영과 관리 책임을
            맡은 회원을 의미합니다.
          </li>
          <li>"크루"라 함은 클럽에 가입된 회원을 의미합니다.</li>
          <li>
            "모임"이라 함은 클럽에서 회원들이 직접 만드는 활동으로, 클럽의
            목표나 관심사에 맞추어 진행되는 행사를 의미합니다.
          </li>
          <li>
            "게시물"이라 함은 회원이 서비스를 이용하면서 서비스 내에 게시한 모든
            정보 형태의 글, 사진, 동영상, 파일 및 링크 등을 의미합니다.
          </li>
          <li>
            "유료 서비스"라 함은 회사가 유료로 제공하는 정보콘텐츠, 프리미엄
            서비스, 기타 유료 콘텐츠 및 제반 서비스를 의미합니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 3 조 (약관의 게시와 개정)</h2>
        <ol>
          <li>
            회사는 법률이나 서비스의 변경사항을 반영하기 위한 목적으로 본
            약관이나 이용 방법, 도움말, 운영수칙, 안내 및 고지사항을 수정할 수
            있습니다.
          </li>
          <li>
            본 약관의 변경사항은 서비스 "공지사항"과 웹사이트 초기화면에
            게시합니다.
          </li>
          <li>
            회사가 전항에 따라 개정약관을 게시하면서 회원에게 15일 이내에
            명시적인 거부 의사나 별도의 의견이 없는 경우, 회원이 개정된
            이용약관에 동의한 것으로 간주합니다.
          </li>
          <li>
            회원이 개정약관의 적용에 동의하지 않는 경우, 회사는 개정약관을
            적용한 서비스를 제공할 수 없으며, 이 경우 회원은 이용계약을 해지할
            수 있습니다.
          </li>
          <li>
            회사는 유료 서비스 및 개별 서비스에 대해 별도의 이용약관 및 정책을
            둘 수 있으며, 해당 내용이 이 약관과 상충할 경우 본 약관이 우선
            적용됩니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 4 조 (회원가입 및 관리)</h2>
        <ol>
          <li>
            이용계약은 회원이 되고자 하는 자('가입신청자')가 서비스 실행 시
            약관에 동의하고 회원가입을 신청한 후, 회사가 이를 승낙함으로써
            체결됩니다.
          </li>
          <li>
            회원가입 신청 시 필수 정보를 입력하고, "확인"을 누르는 방법으로 회원
            가입이 이루어집니다. 회사는 필요 시 별도의 자료 제출을 요구할 수
            있습니다.
          </li>
          <li>
            회원가입 시 수집되는 개인정보는 관련법 및 회사의 개인정보 처리방침에
            따라 처리됩니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 5 조 (개인정보 보호)</h2>
        <ol>
          <li>
            회사는 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
            노력하며, 개인정보의 수집 및 이용에 관해서는 개인정보 처리방침을
            따릅니다.
          </li>
          <li>
            회사는 서비스 제공을 위해 필요한 최소한의 개인정보만을 수집하며,
            수집된 개인정보는 명시된 목적 이외의 용도로 사용되지 않습니다.
          </li>
          <li>
            회원은 언제든지 프로필 화면을 통해 본인의 개인정보를 조회하거나
            수정할 수 있습니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 6 조 (이용계약의 체결 및 제한)</h2>
        <ol>
          <li>
            회사는 다음 각 호에 해당하는 경우, 회원가입 신청을 승낙하지 않거나
            사후에 이용계약을 해지할 수 있습니다:
          </li>
          <ul>
            <li>
              이전에 회원자격을 상실한 적이 있는 자가 재가입을 신청하는 경우(단,
              회사의 재가입 승낙이 있는 경우는 예외)
            </li>
            <li>타인의 명의를 도용하거나 허위 정보를 기재한 경우</li>
            <li>회사가 제시하는 필수 정보를 기재하지 않은 경우</li>
          </ul>
          <li>
            회사는 이용계약 체결 시 회원의 신원을 확인하기 위해 본인인증을
            요청할 수 있으며, 관계 법령에 따른 절차에 따릅니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 7 조 (회원의 의무)</h2>
        <ol>
          <li>
            회원은 자신의 아이디 및 비밀번호에 대한 관리 책임을 가지며, 이를
            타인에게 양도하거나 대여할 수 없습니다.
          </li>
          <li>
            회원이 자신의 계정이 도용되었거나 개인정보가 유출된 사실을 인지한
            경우, 즉시 회사에 통보하고 회사의 안내를 따라야 합니다.
          </li>
          <li>
            회원은 온라인과 오프라인에서 다음과 같은 행위를 해서는 안 됩니다:
          </li>
          <ul>
            <li>타인의 정보를 도용하거나 사칭하는 행위</li>
            <li>불법적인 거래 또는 사기 행위</li>
            <li>음란, 폭력적이거나 공서양속에 반하는 내용 게시</li>
            <li>회사의 서비스를 무단으로 상업적 목적으로 사용하는 행위</li>
          </ul>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 8 조 (회사의 의무)</h2>
        <ol>
          <li>
            회사는 서비스를 안정적으로 제공하기 위해 최선을 다하며, 지속적인
            시스템 점검 및 보수를 실시합니다.
          </li>
          <li>
            회사는 회원의 개인정보 보호를 위해 개인정보 보호법 및 개인정보
            처리방침에 따라 적절한 보호 조치를 취합니다.
          </li>
          <li>
            회사는 서비스 이용과 관련된 회원의 의견이나 불만 사항을 접수하고,
            이를 신속히 처리해야 합니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 9 조 (서비스의 제공 및 제한)</h2>
        <ol>
          <li>
            회사는 연중무휴, 24시간 서비스를 제공하기 위해 노력하지만,
            정기점검이나 유지보수를 위해 일시적으로 서비스 제공이 중단될 수
            있습니다.
          </li>
          <li>
            회사는 서비스 제공에 있어 이용자에게 캐싱된 정보를 제공하며, 이를
            원치 않는 회원은 서비스를 이용하지 않을 수 있습니다.
          </li>
          <li>
            서비스는 무선 네트워크 환경에서 동기화될 수 있으며, GPS 기반의
            서비스는 사용자의 동의하에 위치 정보를 수집하여 제공됩니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>
          제 10 조 (게시물의 관리 및 저작권)
        </h2>
        <ol>
          <li>
            회원이 게시한 게시물의 저작권은 해당 게시물의 작성자에게 귀속됩니다.
          </li>
          <li>
            회원은 서비스 내에서 게시한 게시물을 삭제하거나 관리할 수 있습니다.
            단, 다른 회원이 스크랩하거나 공유한 경우에는 삭제되지 않을 수
            있습니다.
          </li>
          <li>
            회사는 회원이 게시한 게시물이 본 약관 및 관련 법령에 위반될 경우
            이를 삭제할 권리를 가집니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>
          제 11 조 (정보의 제공 및 광고의 게재)
        </h2>
        <ol>
          <li>
            회사는 회원에게 서비스 이용에 필요하다고 판단되는 정보를 이메일,
            메시지 등을 통해 제공할 수 있습니다.
          </li>
          <li>
            회사는 서비스 내에 광고를 게재할 수 있으며, 회원이 광고주와의 거래로
            발생하는 문제에 대해서는 회사가 책임지지 않습니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 12 조 (서비스 변경 및 중단)</h2>
        <ol>
          <li>
            회사는 서비스 이용률 저하, 기술적 문제 등으로 서비스의 일부 또는
            전부를 변경하거나 중단할 수 있습니다.
          </li>
          <li>
            회사는 서비스 변경 또는 중단 시 30일 전에 사전 공지하며, 특별한
            규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 13 조 (계약 해지)</h2>
        <ol>
          <li>
            회원은 언제든지 서비스 내에서 계정을 삭제하여 이용계약을 해지할 수
            있으며, 해지 즉시 회원의 데이터는 소멸됩니다.
          </li>
          <li>
            회사는 회원이 본 약관을 위반하거나 운영에 방해가 된다고 판단될 경우,
            서비스 이용을 제한하거나 이용계약을 해지할 수 있습니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 14 조 (책임 제한)</h2>
        <ol>
          <li>
            회사는 천재지변 등 불가항력적인 사유로 인하여 서비스를 제공할 수
            없는 경우, 그에 대한 책임이 면제됩니다.
          </li>
          <li>
            회원의 귀책사유로 인한 서비스 이용 장애에 대해서는 책임을 지지
            않습니다.
          </li>
          <li>
            회사는 회원 간에 발생한 분쟁에 대해 관여하지 않으며, 이에 대한
            책임도 지지 않습니다.
          </li>
        </ol>

        <h2 style={{ fontSize: "1.6em" }}>제 15 조 (준거법 및 재판관할)</h2>
        <ol>
          <li>
            회사와 회원 간에 발생한 분쟁은 대한민국 법을 준거법으로 합니다.
          </li>
          <li>
            회사와 회원 간의 분쟁에 관한 소송은 민사소송법 상의 법원을
            관할법원으로 합니다.
          </li>
        </ol>

        <p>
          <strong>이 약관은 2024년 6월 25일부터 적용됩니다.</strong>
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
